import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://serverless-api.megbhujang.workers.dev/api/posts"
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div>
      <h1 class="head">Posts</h1>
      
      {posts.map((post) => (
        <div class="row">
        <h3 class ="postname">{post.id}. {post.title}</h3>
        <p class="pbody">{post.text}</p>
        <p class="pub">{post.published_at}</p>
        <div key={post.id}>
          <h2>

            {/* <Link to={`/posts/${post.id}`}>{post.title}</Link> */}
          </h2>
          </div>
        </div>
      ))}
    
    </div>
  );
};

export default Posts;